/**
 * Collections a gérer de manière générique
 */
export default [
  {
    collectionName: "traitements",
    notifications: [
      { type: "CREATE", message: "Traitement ajouté" },
      { type: "UPDATE", message: "Traitement modifié" },
      { type: "DELETE", message: "Traitement supprimé" },
    ],
  },
  {
    collectionName: "potentiels",
    notifications: [
      { type: "CREATE", message: "Potentiel ajouté" },
      { type: "UPDATE", message: "Potentiel modifié" },
      { type: "DELETE", message: "Potentiel supprimé" },
    ],
  },

  {
    collectionName: "raison_facturations",
    notifications: [
      { type: "CREATE", message: "Raison Facturation ajoutée" },
      { type: "UPDATE", message: "Raison Facturation modifiée" },
      { type: "DELETE", message: "Raison Facturation supprimée" },
    ],
  },
  {
    collectionName: "facture_annexes",
    notifications: [
      { type: "CREATE", message: "Facture annexe ajoutée" },
      { type: "UPDATE", message: "Facture annexe modifiée" },
      { type: "DELETE", message: "Facture annexe supprimée" },
    ],
  },

  {
    collectionName: "activites",
    notifications: [
      { type: "CREATE", message: "Activité ajoutée" },
      { type: "UPDATE", message: "Activité modifiée" },
      { type: "DELETE", message: "Activité supprimée" },
    ],
  },
  {
    collectionName: "affaires",
    notifications: [
      { type: "CREATE", message: "Affaire ajoutée" },
      { type: "UPDATE", message: "Affaire modifiée" },
      { type: "DELETE", message: "Affaire supprimée" },
    ],
  },
  {
    collectionName: "articles",
    notifications: [
      { type: "CREATE", message: "Article ajouté" },
      { type: "UPDATE", message: "Article modifié" },
      { type: "DELETE", message: "Article supprimé" },
    ],
  },
  {
    collectionName: "conditionnements",
    notifications: [
      { type: "CREATE", message: "Conditionnement ajouté" },
      { type: "UPDATE", message: "Conditionnement modifié" },
      { type: "DELETE", message: "Conditionnement supprimé" },
    ],
  },
  {
    collectionName: "contacts",
    notifications: [
      { type: "CREATE", message: "Contact ajouté" },
      { type: "UPDATE", message: "Contact modifié" },
      { type: "DELETE", message: "Contact supprimé" },
    ],
  },
  {
    collectionName: "emails",
    notifications: [
      { type: "CREATE", message: "Email envoyé" },
      { type: "UPDATE", message: "Email modifié" },
      { type: "DELETE", message: "Email supprimé" },
    ],
  },
  {
    collectionName: "entreprises",
    notifications: [
      { type: "CREATE", message: "Entreprise ajoutée" },
      { type: "UPDATE", message: "Entreprise modifiée" },
      { type: "DELETE", message: "Entreprise supprimée" },
    ],
  },
  {
    collectionName: "etats",
    notifications: [
      { type: "CREATE", message: "État ajouté" },
      { type: "UPDATE", message: "État modifié" },
      { type: "DELETE", message: "État supprimé" },
    ],
  },
  {
    collectionName: "familles",
    notifications: [
      { type: "CREATE", message: "Famille ajoutée" },
      { type: "UPDATE", message: "Famille modifiée" },
      { type: "DELETE", message: "Famille supprimée" },
    ],
  },
  {
    collectionName: "langues",
    notifications: [
      { type: "CREATE", message: "Langue ajoutée" },
      { type: "UPDATE", message: "Langue modifiée" },
      { type: "DELETE", message: "Langue supprimée" },
    ],
  },
  {
    collectionName: "lots",
    notifications: [
      { type: "CREATE", message: "Article ajouté" },
      { type: "UPDATE", message: "Article modifié" },
      { type: "DELETE", message: "Article supprimé" },
    ],
  },
  {
    collectionName: "paiements",
    notifications: [
      { type: "CREATE", message: "Paiement ajouté" },
      { type: "UPDATE", message: "Paiement modifié" },
      { type: "DELETE", message: "Paiement supprimé" },
    ],
  },
  {
    collectionName: "pays",
    notifications: [
      { type: "CREATE", message: "Pays ajouté" },
      { type: "UPDATE", message: "Pays modifié" },
      { type: "DELETE", message: "Pays supprimé" },
    ],
  },
  {
    collectionName: "transports",
    notifications: [
      { type: "CREATE", message: "Transport ajouté" },
      { type: "UPDATE", message: "Transport modifié" },
      { type: "DELETE", message: "Transport supprimé" },
    ],
  },
  {
    collectionName: "etablissements",
    notifications: [
      { type: "CREATE", message: "Site ajouté" },
      { type: "UPDATE", message: "Site modifié" },
      { type: "DELETE", message: "Site supprimé" },
    ],
  },
  {
    collectionName: "users",
    notifications: [
      { type: "CREATE", message: "Utilisateur ajouté" },
      { type: "UPDATE", message: "Utilisateur modifié" },
      { type: "DELETE", message: "Utilisateur supprimé" },
    ],
  },
  {
    collectionName: "roles",
    notifications: [
      { type: "CREATE", message: "Rôle ajouté" },
      { type: "UPDATE", message: "Rôle modifié" },
      { type: "DELETE", message: "Rôle supprimé" },
    ],
  },
  {
    collectionName: "produits_origines",
    notifications: [
      { type: "CREATE", message: "Origine ajoutée" },
      { type: "UPDATE", message: "Origine modifiée" },
      { type: "DELETE", message: "Origine supprimée" },
    ],
  },
  {
    collectionName: "bordereau_achat",
    notifications: [
      { type: "UPDATE", message: "Bordereau d'achat créé" },
      { type: "DELETE", message: "Bordereau d'achat  supprimé" },
    ],
  },
  {
    collectionName: "litiges",
    notifications: [
      { type: "CREATE", message: "Type de litige ajouté" },
      { type: "UPDATE", message: "Type de litige modifié" },
      { type: "DELETE", message: "Type de litige supprimé" },
    ],
  },
  {
    collectionName: "commentaires",
    notifications: [
      { type: "CREATE", message: "Commentaire ajouté" },
      { type: "UPDATE", message: "Commentaire modifié" },
      { type: "DELETE", message: "Commentaire supprimé" },
    ],
  },
  "rights",
  "flux",
  {
    collectionName: "stock/produits_transformes",
    notifications: [
      { type: "CREATE", message: "Produit transformé ajouté" },
      { type: "UPDATE", message: "Produit transformé modifié" },
      { type: "DELETE", message: "Produit transformé supprimé" },
    ],
  },
  {
    collectionName: "stock/matieres_premieres",
    notifications: [
      { type: "CREATE", message: "Ajout au stock effectué" },
      { type: "UPDATE", message: "Stock modifié" },
      { type: "DELETE", message: "Stock supprimé" },
    ],
  },
  {
    collectionName: "packinglists",
    notifications: [
      { type: "CREATE", message: "Packing list ajoutée" },
      { type: "UPDATE", message: "Packing list modifiée" },
      { type: "DELETE", message: "Packing list supprimée" },
    ],
  },
  {
    collectionName: "silolists",
    notifications: [
      { type: "CREATE", message: "Silo list ajoutée" },
      { type: "UPDATE", message: "Silo list modifiée" },
      { type: "DELETE", message: "Silo list supprimée" },
    ],
  },
  {
    collectionName: "notes",
    notifications: [
      { type: "CREATE", message: "Note ajoutée" },
      { type: "UPDATE", message: "Note modifiée" },
      { type: "DELETE", message: "Note supprimée" },
    ],
  },
  {
    collectionName: "documents",
    notifications: [
      { type: "CREATE", message: "Document ajouté" },
      { type: "UPDATE", message: "Document modifié" },
      { type: "DELETE", message: "Document supprimé" },
    ],
  },
  {
    collectionName: "type_documents",
    notifications: [
      { type: "CREATE", message: "Type de document ajouté" },
      { type: "UPDATE", message: "Type de document modifié" },
      { type: "DELETE", message: "Type de document supprimé" },
    ],
  },
  {
    collectionName: "type_notes",
    notifications: [
      { type: "CREATE", message: "Type de note ajouté" },
      { type: "UPDATE", message: "Type de note modifié" },
      { type: "DELETE", message: "Type de note supprimé" },
    ],
  },
  {
    collectionName: "logisticiens",
    notifications: [
      { type: "CREATE", message: "Logisticien ajouté" },
      { type: "UPDATE", message: "Logisticien modifié" },
      { type: "DELETE", message: "Logisticien supprimé" },
    ],
  },
  {
    collectionName: "email-blocage-planning",
    notifications: [
      { type: "CREATE", message: "Email ajouté" },
      { type: "UPDATE", message: "Email modifié" },
      { type: "DELETE", message: "Email supprimé" },
    ],
  },
  "type_affaires",
  {
    collectionName: "campagne-tafs",
    notifications: [
      { type: "CREATE", message: "Campagne ajoutée" },
      { type: "UPDATE", message: "Campagne modifiée" },
      { type: "DELETE", message: "Campagne supprimée" },
    ],
  },
  {
    collectionName: "article-regroupements",
    notifications: [
      { type: "CREATE", message: "Article regroupement ajouté" },
      { type: "UPDATE", message: "Article regroupement modifié" },
      { type: "DELETE", message: "Article regroupement supprimé" },
    ],
  },
  {
    collectionName: "article-regroupement-prixs",
    notifications: [
      { type: "CREATE", message: "Prix ajouté" },
      { type: "UPDATE", message: "Prix modifié" },
      { type: "DELETE", message: "Prix supprimé" },
    ],
  },
  {
    collectionName: "cahiers-des-charges",
    notifications: [
      { type: "CREATE", message: "Cahier des charges ajouté" },
      { type: "UPDATE", message: "Cahier des charges modifié" },
      { type: "DELETE", message: "Cahier des charges supprimé" },
    ],
  },
  {
    collectionName: "proformas",
    notifications: [
      { type: "CREATE", message: "Proforma ajoutée" },
      { type: "UPDATE", message: "Proforma modifiée" },
      { type: "DELETE", message: "Proforma supprimée" },
    ],
  },
  {
    collectionName: "caseStockage",
    notifications: [
      { type: "CREATE", message: "Case de stockage ajoutée" },
      { type: "UPDATE", message: "Case de stockage modifiée" },
      { type: "DELETE", message: "Case de stockage supprimée" },
    ],
  },
  {
    collectionName: "planningStockage",
    notifications: [
      { type: "CREATE", message: "Case de stockage ajoutée" },
      { type: "UPDATE", message: "Case de stockage modifiée" },
      { type: "DELETE", message: "Case de stockage supprimée" },
    ],
  },
  {
    collectionName: "zone-bbs",
    notifications: [
      { type: "CREATE", message: "Zone BB ajoutée" },
      { type: "UPDATE", message: "Zone BB modifiée" },
      { type: "DELETE", message: "Zone BB supprimée" },
    ],
  },
  {
    collectionName: "cellule-bbs",
    notifications: [
      { type: "CREATE", message: "Cellule ajoutée" },
      { type: "UPDATE", message: "Cellule modifiée" },
      { type: "DELETE", message: "Cellule supprimée" },
    ],
  },
  {
    collectionName: "mise-a-dispositions",
    notifications: [
      { type: "CREATE", message: "Mise à disposition ajoutée" },
      { type: "UPDATE", message: "Mise à disposition modifiée" },
      { type: "DELETE", message: "Mise à disposition supprimée" },
    ],
  },
  {
    collectionName: "mise-a-dispositions-negoce",
    notifications: [
      { type: "CREATE", message: "Mise à disposition ajoutée" },
      { type: "UPDATE", message: "Mise à disposition modifiée" },
      { type: "DELETE", message: "Mise à disposition supprimée" },
    ],
  },
  {
    collectionName: "meg-commentaire-articles",
    notifications: [
      { type: "CREATE", message: "Commentaire ajouté" },
      { type: "UPDATE", message: "Commentaire modifié" },
      { type: "DELETE", message: "Commentaire supprimé" },
    ],
  },
  {
    collectionName: "meg-model-tvas",
    notifications: [
      { type: "CREATE", message: "Modèle TVA ajouté" },
      { type: "UPDATE", message: "Modèle TVA modifié" },
      { type: "DELETE", message: "Modèle TVA supprimé" },
    ],
  },
  {
    collectionName: "type-non-conformites",
    notifications: [
      { type: "CREATE", message: "Type de non conformité ajoutée" },
      { type: "UPDATE", message: "Type de non conformité modifiée" },
      { type: "DELETE", message: "Type de non conformité supprimée" },
    ],
  },
  {
    collectionName: "source_prospects",
    notifications: [
      { type: "CREATE", message: "Source prospect ajoutée" },
      { type: "UPDATE", message: "Source prospect modifiée" },
      { type: "DELETE", message: "Source prospect supprimée" },
    ],
  },
  {
    collectionName: "non-conformites",
    notifications: [
      { type: "CREATE", message: "Non conformité ajoutée" },
      { type: "UPDATE", message: "Non conformité modifiée" },
      { type: "DELETE", message: "Non conformité supprimée" },
    ],
  },
  {
    collectionName: "etablissement_contact_non_conformites",
    notifications: [
      { type: "CREATE", message: "Contact ajouté" },
      { type: "UPDATE", message: "Contact modifié" },
      { type: "DELETE", message: "Contact supprimé" },
    ],
  },
  {
    collectionName: 'pir-pcrs',
    notifications: [
      { type: "CREATE", message: "Pir/Pcr ajouté" },
      { type: "UPDATE", message: "Pir/Pcr modifié" },
      { type: "DELETE", message: "Pir/Pcr supprimé" },
    ],
  },
  {
    collectionName: 'lot-mad-negoces',
    notifications: [
      { type: "CREATE", message: "Lot Mad negoce ajouté" },
      { type: "UPDATE", message: "Lot Mad negoce modifié" },
      { type: "DELETE", message: "Lot Mad negoce supprimé" },
    ],
  },
];
