import React, { Component } from "react";

import { Grid, MenuItem, Typography, Fab, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, Settings } from "@material-ui/icons";

import CollectionCrud from "../../common/Components/CollectionCrud";
import { collectionActions } from "../../../reducers/collectionsReducer";
import { connect } from "react-redux";
import TextInput from "../../common/Components/TextInput";
import CheckboxInput from "../../common/Components/CheckboxInput";
import NumberInput from "../../common/Components/NumberInput";
import { DateInput } from "@oniti/oniti-inputs";
import MiseADispositionCss from "./css/MiseADispositionCss";
import LotMadNegoce from "../LotMadNegoce";
import DocumentPicker from "../../common/Components/DocumentPicker";

const LOCALSTORAGE_FILTERS_KEY = "mise-a-disposition-negoce-filters";

class MadNegoce extends Component {
  state = {
    openLotModal: false,
    filterSelectedStatus: ["ouvert", "ferme"],
    filterSelectedFamilles: ["all"],
    filterSelectedPriorities: ["all"],
  };
  componentDidMount() {
    this.fetchMads();
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }

    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  loadAsyncData(filters) {
    let { dispatch } = this.props;

    return collectionActions(
      dispatch,
      "mise-a-dispositions-negoce",
      "INDEX",
      null,
      null,
      true
    );
  }

  getHeader(mad) {
    const { classes } = this.props;
    return (
      <div className={classes.header + " " + classes.marginBottom}>
        {this.getStatutDiv(mad.statut)}
      </div>
    );
  }

  getCommentaire(commentaire, length = 50) {
    if (!commentaire) return "";
    if (commentaire.length > length) {
      return commentaire.substring(0, length) + "...";
    }
    return commentaire;
  }

  getCommentairePart(title, commentaire, length = 50) {
    if (!commentaire) return null;
    const { classes } = this.props;
    return (
      <div className={classes.commentaireContainer}>
        <div>{title}</div>
        <div>{this.getCommentaire(commentaire, length)}</div>
      </div>
    );
  }

  getImagesPreview(images) {
    const { classes } = this.props;
    return (
      <div className={classes.preview_image_container}>
        {images.map((image, index) => (
          <img
            key={index}
            className={classes.preview_image}
            src={image.url}
            alt={image.filename}
          />
        ))}
      </div>
    );
  }

  getStatutDiv(statut) {
    if (!statut) return null;
    let color = null;
    switch (statut) {
      case "ouvert":
        color = "rgb(15 143 15)";
        break;
      case "ferme":
        color = "rgb(239 129 34)";
        break;
      default:
        color = null;
    }
    const label = statut
      .split("-")
      .map((word) => word[0].toUpperCase())
      .join("-");
    const { classes } = this.props;
    return (
      <div
        className={classes.headerDivInfo}
        style={{
          backgroundColor: color,
        }}
        title={statut}
      >
        {label}
      </div>
    );
  }

  getLotConditionnementLibelle(lots) {
    if (!lots || lots.length === 0) return null;
    return lots.map((lot) => {
      return (
        <div key={lot.uuid}>
          {lot.conditionnement_nom} / {lot.article_nom}
        </div>
      );
    });
  }

  displayMad(mad, options) {
    const { onClickEditHandler } = options;
    const { classes } = this.props;

    return (
      <div
        key={mad.uuid}
        className={classes.madCard}
        onClick={() => {
          this.setState({
            enableRegisterScroll: false,
          });
          onClickEditHandler(mad);
        }}
      >
        <div
          className={classes.infos}
          style={{
            height: "100%",
          }}
        >
          <div className={classes.reference + " " + classes.marginBottom}>
            {/* {this.getSelectCheckbox(mad)} */}
            <span>{mad.reference}</span>
          </div>
          <div className={classes.body}>
            <div className={classes.article + " " + classes.marginBottom}>
              {mad.nbre_articles} articles
              {/* {this.getLotConditionnementLibelle(mad.lot_mad_negoces)} */}
            </div>
            <hr />
            {this.getHeader(mad)}
            <hr />
            {this.getCommentairePart("Commentaire", mad.commentaire_general)}
            {this.getImagesPreview(mad.firsts_images)}
            <div className={classes.photoIconContainer}>
              <DocumentPicker
                documentable_uuid={mad.uuid}
                documentable_type="App\Models\MiseADispositionNegoce"
                hasDocument={mad.has_document}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDataCustomCallback(data, options) {
    const { ccrudOptions } = this.state;
    if (!ccrudOptions) {
      this.setState({ ccrudOptions: options });
    }

    return this.listing(data, options);
  }

  listing(data, options) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.madContainer}>
        {data.map((mad) => this.displayMad(mad, options))}
      </Grid>
    );
  }

  onChangeFilterHandler(name, e) {
    let values = e.target.value;

    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState({ [name]: values }, () => {
      this.fetchMads();
      this.saveFilters();
    });
  }

  saveFilters() {
    const {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterRecherche,
      viewMobile,
    } = this.state;
    const filters = {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterRecherche,
      viewMobile,
    };
    localStorage.setItem(LOCALSTORAGE_FILTERS_KEY, JSON.stringify(filters));
  }

  loadFilters() {
    const filters = JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY));
    if (filters) {
      this.setState(filters);
    }
  }

  fetchMads(timeout = 0) {
    const {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSortBy,
    } = this.state;
    const params = {
      filterSelectedStatus,
      filterSelectedFamilles,
      filterSelectedPriorities,
      filterSelectedSitesUuid,
      filterSortBy,
    };
    clearTimeout(this.state.fetchRequest);
    // eslint-disable-next-line
    this.state.fetchRequest = setTimeout(() => {
      collectionActions(
        this.props.dispatch,
        "mise-a-dispositions-negoce",
        "INDEX_WITHOUT_DISPATCH",
        {
          params,
        }
      ).then((response) => {
        this.setState({ localData: response.data });
      });
    }, timeout);
  }

  getStatusOptions() {
    const status = [
      { value: "all", label: "Tous" },
      { value: "ouvert", label: "Ouvert" },
      { value: "ferme", label: "Fermé" },
    ];

    return status.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  getPrioritiesOptions(withAll = false) {
    let priorities = [
      { value: "all", label: "Toutes" },
      { value: "p1", label: "P1" },
      { value: "p2", label: "P2" },
      { value: "p3", label: "P3" },
    ];

    if (!withAll) {
      priorities = priorities.filter((priority) => priority.value !== "all");
    }

    return priorities.map((priority) => (
      <MenuItem key={priority.value} value={priority.value}>
        {priority.label}
      </MenuItem>
    ));
  }

  getAddBtn(onClickAddHandler) {
    if (!onClickAddHandler) return null;
    const { classes } = this.props;
    return (
      <div className={classes.contentCenter}>
        <Fab
          color="primary"
          size="small"
          aria-label="Add"
          onClick={onClickAddHandler}
          className={classes.addButton}
        >
          <Add />
        </Fab>
      </div>
    );
  }

  header() {
    const { ccrudOptions } = this.state;

    const { onClickAddHandler } = ccrudOptions || {};
    const { filterSelectedStatus, filterSelectedPriorities } = this.state;
    const { classes, madsNegoceStore } = this.props;

    return (
      <Grid container>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Status"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedStatus"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedStatus,
            }}
            select
          >
            {this.getStatusOptions()}
          </TextInput>
        </Grid>
        <Grid item lg={1} xs={12}>
          <TextInput
            label="Priorité"
            className={classes.selectContainer}
            margin="normal"
            name="filterSelectedPriorities"
            collectionStore={madsNegoceStore}
            onChangeHandler={this.onChangeFilterHandler.bind(this)}
            fullWidth
            inputProps={{
              multiple: true,
              value: filterSelectedPriorities,
            }}
            select
          >
            {this.getPrioritiesOptions(true)}
          </TextInput>
        </Grid>
        <Grid item lg={3} xs={12}>
          {this.getAddBtn(onClickAddHandler)}
        </Grid>
      </Grid>
    );
  }

  /**
   * Traite les actions remontées par la modale de création de Lot.
   *
   * À la création d'un Lot, l'action 'close' est générée avant
   * l'action 'create'.
   *
   * @param {string} action - L'identifiant de l'action.
   * @param {Lot}    lot    - L'objet Lot retourné par l'action.
   */
  handleLotModalActions(action, lot, clicknext) {
    if (action === "close") {
      this.setState({ openLotModal: false });
    }
    if (action === "create" || action === "update") {
      this.setState({ openLotModal: false }, () => {
        collectionActions(
          this.props.dispatch,
          "mise-a-dispositions-negoce",
          "RESET_DETAIL",
          null,
          (mad) => {
            this.setState({ openLotModal: true }, () => {
              this.setState({ selectedMad: mad });
            });
          }
        );

        // collectionActions(
        //   this.props.dispatch,
        //   "mise-a-dispositions-negoce",
        //   "RESET_LIST",
        //   null,
        //   () => {
        //     // Rouvre la modale Lot pour permettre la création du Lot suivant
        //     this.setState({ openLotModal: clicknext });
        //     // Recharge la liste des Affaires pour mettre à jour
        //     // la colonne Articles
        //     this.loadAsyncData(this.state.current_filter, true);

        //     // if (!clicknext) {
        //     //   this.props.history
        //     //     .push
        //     //     // buildRoute("/affaires/:uuid", { uuid: this.state.affaireUuid })
        //     //     ();
        //     // }
        //   }
        // );
      });
    } else if (action === "update") {
      this.setState({ openLotModal: true });
    }
  }

  getCreateUpdateModalContent(mad, madsNegoceStore, onChangeHandler) {
    const { classes } = this.props;

    const { selectedMadsUuids, openLotModal } = this.state;

    let {
      uuid,
      reference,
      description,
      entreprise_fournisseur,
      entreprise_etablissement,
      statut,
      conlusion,
      commercial_fournisseur,
      date_fin,
      is_disponible,
      date_disponibilite,
      commentaire_disponibilite,
      is_spot,
      is_recurrent,
      date_fin_lot,
      commentaire_lot,
      is_complet,
      nb_unite,
      surface_m2,
      priorite,
      statut_achat,
      validite_offre,
      clients_potentiels,
      commentaire_general,
      is_valid,
      validated_at,
      valid_user_full_name,
    } = mad;

    return (
      <>
        <Grid container className={classes.section}>
          <Grid item xs={3}>
            <TextInput
              label="Référence"
              value={reference}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="reference"
              onChangeHandler={onChangeHandler}
              disabled
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <label htmlFor="description" style={{ marginLeft: "5px" }}>
              Description
            </label>
            <textarea
              id="description"
              onChange={(e) => onChangeHandler("description", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Description"
              value={description}
            ></textarea>
          </Grid>{" "}
          <Grid item xs={12} lg={3}>
            <TextInput
              label="Fournisseur"
              value={entreprise_fournisseur}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="entreprise_fournisseur"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextInput
              label="Etablissement"
              value={entreprise_etablissement}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="entreprise_etablissement"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextInput
              label="Statut"
              value={statut}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="statut"
              onChangeHandler={onChangeHandler}
              fullWidth
              select
            >
              <MenuItem value="ouvert">Ouvert</MenuItem>
              <MenuItem value="ferme">Fermé</MenuItem>
            </TextInput>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextInput
              label="Conclusion"
              value={conlusion}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="conlusion"
              onChangeHandler={onChangeHandler}
              fullWidth
              select
              disabled={statut == "ouvert"}
            >
              <MenuItem value="interet_gagne">Intérêt Gagné</MenuItem>
              <MenuItem value="interet_perdu">Intérêt Perdu</MenuItem>
              <MenuItem value="interet_pas_de_client">
                Intérêt Pas de Client
              </MenuItem>
              <MenuItem value="pas_d_interet">Pas d'Intérêt</MenuItem>
            </TextInput>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextInput
              label="Commercial"
              value={commercial_fournisseur}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="commercial_fournisseur"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DateInput
              id="date_fin"
              label="Date de fin de la Mad"
              format="dd/MM/yyyy"
              value={date_fin}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="date_fin"
              onChangeHandler={onChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={1} className={classes.checkBoxInputField}>
            <CheckboxInput
              id="is_disponible"
              label="Disponible"
              value={is_disponible}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="is_disponible"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DateInput
              id="date_disponibilite"
              label="Date dispo"
              format="dd/MM/yyyy"
              value={date_disponibilite}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="date_disponibilite"
              onChangeHandler={onChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <label
              htmlFor="commentaire_disponibilite"
              style={{ marginLeft: "5px" }}
            >
              Commentaire disponibilité
            </label>
            <textarea
              id="commentaire_disponibilite"
              onChange={(e) => onChangeHandler("commentaire_disponibilite", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Commentaire disponibilité"
              value={commentaire_disponibilite}
            ></textarea>
          </Grid>
          <Grid item xs={12} lg={2} className={classes.checkBoxInputField}>
            <CheckboxInput
              id="is_spot"
              label="Spot"
              value={is_spot}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="is_spot"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={1} className={classes.checkBoxInputField}>
            <CheckboxInput
              id="is_recurrent"
              label="Récurrent"
              value={is_recurrent}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="is_recurrent"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <DateInput
              id="date_fin_lot"
              label="Date fin du lot"
              format="dd/MM/yyyy"
              value={date_fin_lot}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="date_fin_lot"
              onChangeHandler={onChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <label htmlFor="commentaire_lot" style={{ marginLeft: "5px" }}>
              Commentaire lot
            </label>
            <textarea
              id="commentaire_lot"
              onChange={(e) => onChangeHandler("commentaire_lot", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Commentaire lot"
              value={commentaire_lot}
            ></textarea>
          </Grid>
          <Grid item xs={12} lg={2} className={classes.checkBoxInputField}>
            <CheckboxInput
              id="is_complet"
              label="Complet"
              value={is_complet}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="is_complet"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <NumberInput
              id="nb_unite"
              label="Nb unités"
              value={nb_unite}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="nb_unite"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <NumberInput
              id="surface_m2"
              label="Surface (m2 au sol)"
              value={surface_m2}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="surface_m2"
              onChangeHandler={onChangeHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextInput
              label="Priorité"
              value={priorite}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="priorite"
              onChangeHandler={onChangeHandler}
              fullWidth
              select
            >
              {this.getPrioritiesOptions()}
            </TextInput>
          </Grid>
          <Grid item xs={12} lg={4}>
            {/* EMPTY */}
          </Grid>
          <Grid item xs={12} lg={2}>
            <TextInput
              label="Statut achat"
              value={statut_achat}
              margin="normal"
              collectionStore={madsNegoceStore}
              name="statut_achat"
              onChangeHandler={onChangeHandler}
              fullWidth
              select
            >
              <MenuItem value="oui">Oui</MenuItem>
              <MenuItem value="non">Non</MenuItem>
              <MenuItem value="offre_en_cour">Offre en cour</MenuItem>
            </TextInput>
          </Grid>
          <Grid item xs={12} lg={9}>
            <DateInput
              id="validite_offre"
              label="Validité offre"
              format="dd/MM/yyyy"
              value={validite_offre}
              collectionStore={madsNegoceStore}
              margin="normal"
              name="validite_offre"
              onChangeHandler={onChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <label htmlFor="clients_potentiels" style={{ marginLeft: "5px" }}>
              Clients potentiels
            </label>
            <textarea
              id="clients_potentiels"
              onChange={(e) => onChangeHandler("clients_potentiels", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Clients potentiels"
              value={clients_potentiels}
            ></textarea>
          </Grid>
          <Grid item xs={12} lg={6}>
            <label htmlFor="commentaire_general" style={{ marginLeft: "5px" }}>
              Commentaire général
            </label>
            <textarea
              id="commentaire_general"
              onChange={(e) => onChangeHandler("commentaire_general", e)}
              rows="6"
              style={{ marginLeft: "5px", width: "99%" }}
              placeholder="Commentaire général"
              value={commentaire_general}
            ></textarea>
          </Grid>
          <Grid item xs={12} lg={2} className={classes.checkBoxInputField}>
          <CheckboxInput
            id="is_valid"
            label="Validé"
            value={is_valid}
            margin="normal"
            collectionStore={madsNegoceStore}
            name="is_valid"
            onChangeHandler={onChangeHandler}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DateInput
            id="validated_at"
            label="Validité le"
            format="dd/MM/yyyy"
            value={validated_at}
            collectionStore={madsNegoceStore}
            margin="normal"
            name="validite_offre"
            onChangeHandler={onChangeHandler}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            label="Validé par"
            value={valid_user_full_name}
            margin="normal"
            collectionStore={madsNegoceStore}
            name="valid_user_full_name"
            onChangeHandler={onChangeHandler}
            fullWidth
            select
            disabled
          />
        </Grid>
        </Grid>
        {mad.uuid ? (
          <>
            <hr className={classes.hr} />
            <LotMadNegoce
              actionsCallback={this.handleLotModalActions.bind(this)}
              mise_a_disposition_negoce_uuid={uuid}
              createUpdateModalNext={"Suivant"}
              //  modal={{ only: true, open: openLotModal }}
              datas={mad.lot_mad_negoces}
              pagination={false}
              search={true}
            />
          </>
        ) : null}

      </>
    );
  }

  filterLocalData() {
    const { filterRecherche, viewMobile } = this.state;
    const { localData } = this.state;
    if (!filterRecherche || !viewMobile) return localData;
    return localData.filter((mad) => {
      const keys = Object.keys(mad);
      return keys.some((key) => {
        if (typeof mad[key] === "string") {
          return mad[key].toLowerCase().includes(filterRecherche.toLowerCase());
        }
        return false;
      });
    });
  }

  getCommentaireTd(commentaire, length = 20) {
    if (!commentaire) return null;
    return (
      <span
        title={commentaire}
        style={{
          cursor: "help",
        }}
      >
        {commentaire.substring(0, length)}
        {commentaire.length > length ? "..." : ""}
      </span>
    );
  }

  getCheckBoxIsValid(obj, key) {
    return (
      <Checkbox
        checked={obj[key]}
        color="primary"
        onChange={(e) => {
          collectionActions(
            this.props.dispatch,
            "mise-a-dispositions-negoce",
            "UPDATE",
            {
              ...obj,
              [key]: e.target.checked,
            }
          ).then(() => {
            this.fetchMads();
          });
        }}
      />
    );
  }

  getCellsConfig() {
    return [
      {
        datakey: "reference",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "statut_libelle",
        title: "Statut",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "entreprise_fournisseur",
        title: "Fournisseur",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "commentaire_general",
        title: "Commentaire Général",
        sortable: true,
        searchable: true,
        format: (data, key) => {
          return this.getCommentaireTd(data[key], 20);
        },
      },
      {
        datakey: "is_valid",
        title: "Valide",
        sortable: true,
        searchable: true,
        useDataKeyOnSearch: true,
        format: this.getCheckBoxIsValid.bind(this),
      },
      {
        datakey: "valid_user_full_name",
        title: "Validé par",
        sortable: true,
        searchable: true,
      },
    ];
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Settings />
        <span>{"Suppression de la MaD Negoce"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette mise à disposition negoce ?"}
      </Typography>
    );
  }

  getCreateUpdateModalTitle(mad) {
    let title = !!mad
      ? mad.reference
      : "Ajout d'une nouvelle mise à disposition negoce";
    return (
      <div>
        <Settings />
        <span>{title}</span>
      </div>
    );
  }

  // getDocumentBtn(mad) {
  //   const { classes } = this.props;
  //   return (
  //     <DocumentPicker
  //       documentable_uuid={mad.uuid}
  //       documentable_type="App\Models\MiseADispositionNegoce"
  //       className={classes.documentBtn}
  //       hasDocument={mad.has_document}
  //     />
  //   );
  // }

  getModeView() {
    return (
      <Grid container>
        <Grid item xs={3}>
          <CheckboxInput
            label="Vue mobile"
            a
            value={this.state.viewMobile}
            onChangeHandler={(name, e) => {
              this.setState(
                { viewMobile: e.target.value, ccrudOptions: null },
                () => {
                  this.saveFilters();
                }
              );
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  actionsCallback(action, data) {
    switch (action) {
      case "delete":
      case "create":
      case "update":
        this.fetchMads();
        break;
      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { viewMobile } = this.state;

    return (
      <Typography component="div" dir={"ltr"} style={{ padding: 8 * 3 }}>
        {this.getModeView()}
        {this.header()}
        <CollectionCrud
          collectionName={"mise-a-dispositions-negoce"}
          loadDatas={true}
          datas={this.filterLocalData()}
          showBtnEdit={true}
          showBtnAdd={true}
          showBtnDelete={true}
          fullScreenModal={true}
          cancelUpdateCheck={true}
          rights={{
            create: "admin-crud-mises-a-disposition-negoce",
            edit: "admin-crud-mises-a-disposition-negoce",
            delete: "admin-crud-mises-a-disposition-negoce",
          }}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          renderDataCustomCallback={
            viewMobile ? this.renderDataCustomCallback.bind(this) : null
          }
          defaultValues={{
            priorite: "p1",
            complet: false,
          }}
          modalContentStyle={{}}
          actionsCallback={this.actionsCallback.bind(this)}
          cellsConfig={this.getCellsConfig()}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "reference",
            sortType: "desc",
            nodatalabel: "Aucune Mad",
            rowsPerPageOptions: [25, 50],
          }}
          // additionnalControllers={[this.getDocumentBtn.bind(this)]}
          disabledEnterModal={true}
          persistDatatableOptions={{
            id: "mad_negoce_list",
            exipiration_minutes: 60 * 12, // 12 Heures
          }}
        />
      </Typography>
    );
  }
}

MadNegoce = withStyles(MiseADispositionCss)(MadNegoce);

MadNegoce = connect((store) => {
  return {
    madsNegoceStore: store.collections["mise-a-dispositions-negoce"],
    user: store.auth.user,
  };
})(MadNegoce);

export default MadNegoce;
